<template>
  <UtilRouteLink
    class="block-item-teaser"
    :class="`block-teaser--${item.size} image-position--${item.image_position}`"
    :link="item.article.url"
  >
    <div class="block_teaser__imgWrapper">
      <AtomImage
        v-if="item.article?.image?.filename"
        class="block_teaser__img"
        :image="item.article.image"
        :provider="item.article.image.provider ? item.article.image.provider : 'ipx'"
      />
      <div
        v-if="item.article.tag"
        class="block_teaser__tag"
      >
        <span>{{ item.article.tag }}</span>
      </div>
      <div v-if="item.video_teaser" class="block_teaser_video__tag">
        <IonIcon class="block_teaser_video__tag-icon" icon-name="video" />
        <span>{{ item.video_teaser }}</span>
      </div>
    </div>
    <div class="block_teaser_content__wrapper">
      <div
        v-if="item.article && item.article.department"
        class="block_teaser__date"
      >
        {{
          dayjs(item.article.date).locale(locale).format('DD. MMMM YYYY')
        }}
        <span> | </span> {{ item.article.department.name }}
      </div>
      <div class="block_teaser__title">
        <p>
          {{ item.article.title }}
        </p>
      </div>
      <div v-if="item.article.type && item.article.lead" class="block_teaser__teaser">
        <div class="block_teaser__lead">
          <p>
            <span
              v-if="item.article.type"
              class="block_teaser__type"
            >{{ item.article.type }} |</span>
            {{ item.article.lead }}
          </p>
        </div>
      </div>
      <UtilRouteLink
        v-if="item.article.url"
        class="block_teaser__link"
        :link="item.article.url"
      >
        <IonIcon class="block_teaser__icon" icon-name="chevron-right" />
      </UtilRouteLink>
    </div>
  </UtilRouteLink>
</template>

<script setup>
const props = defineProps({
    item: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
    newsSection: {
        type: Boolean,
        default: false,
    },
});

const { dayjs } = useDayJs();
const { locale } = useI18n();

const titleMaxLine = ref(1);
const leadMaxLine = ref(150);
const teaserSizeWidth = ref('345');
const teaserSizeHeight = ref('180');
const colorDate = computed(() => {
    if (props.item.article && props.item.article.department) {
        return props.item.article.department.color_3;
    }
    return 'var(--c-petrol-1)';
});

const setSizes = (size) => {
    switch (size) {
    case 'xl':
        titleMaxLine.value = 1;
        leadMaxLine.value = 4;
        teaserSizeWidth.value = '400px';
        teaserSizeHeight.value = '400px';
        break;
    case 'large':
        titleMaxLine.value = 1;
        leadMaxLine.value = 4;
        teaserSizeWidth.value = '600px';
        teaserSizeHeight.value = '375px';
        break;
    case 'medium':
        titleMaxLine.value = 2;
        leadMaxLine.value = 4;
        teaserSizeWidth.value = '100%';
        teaserSizeHeight.value = '320px';
        break;
    case 'small':
        titleMaxLine.value = 2;
        leadMaxLine.value = 3;
        teaserSizeWidth.value = '100%';
        teaserSizeHeight.value = '320px';
        break;
    case 'small-wide':
        titleMaxLine.value = 2;
        leadMaxLine.value = 4;
        teaserSizeWidth.value = '100%';
        teaserSizeHeight.value = '320px';
        break;
    default:
        titleMaxLine.value = 1;
        leadMaxLine.value = 3;
        teaserSizeWidth.value = '100%';
        teaserSizeHeight.value = '180px';
        break;
    }
};

onMounted(() => {
    setSizes(props.item.size);
});

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.block-item-teaser {
    position: relative;
    display: flex;
    margin-bottom: 45px;
    color: var(--c-petrol-1);

    .block-teaser-news & {
        margin-bottom: 20px;
    }

    .block_teaser__icon, .block_teaser__img {
        transition: all 0.2s ease-out;
    }

    .block-teaser-title {
        overflow: hidden;

        p {
            @include line-clamp(v-bind(titleMaxLine));

            text-overflow: ellipsis;
        }
    }

    .block_teaser__lead {
        overflow: hidden;

        p {
            @include line-clamp(v-bind(leadMaxLine));

            text-overflow: ellipsis;
        }
    }

    &:hover {
        .block_teaser__img {
            transform: scale(1.05);
        }

        .block_teaser_video__tag-icon {
            transform: none;
        }

        .block_teaser__icon {
            transform: translateX(10px);
        }
    }

    &--large {
        .block_teaser_content__wrapper {
            flex-basis: 50%;
        }
    }

    &.block-teaser--xl {
        grid-column: 1 / span 12;

        .block_teaser_content__wrapper {
            display: flex;
            flex-direction: column;
            margin-left: 52px;

            .block_teaser__title {
                overflow: hidden;
                margin-top: 15px;

                font-family: var(--f-family--bold);
                font-size: var(--f-size--h2);
                line-height: var(--l-height--h2);

                /* stylelint-disable-next-line max-nesting-depth */
                @include mobile {
                    font-size: var(--f-size--h3);
                    line-height: var(--l-height--h3);
                }
            }

            @include tablet-portrait {
                margin-left: 0;
            }
        }

        @include tablet-portrait {
            flex-direction: column;
        }
    }

    &.block-teaser--large.image-position--left {
        grid-column: 1 / span 12;

        .block_teaser_content__wrapper {
            display: flex;
            flex-direction: column;
            margin-left: 52px;

            .block_teaser__title {
                margin-top: 15px;
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h3);
                line-height: var(--l-height--h3);
            }

            @include tablet-portrait {
                margin-left: 0;
            }
        }

        @include tablet-portrait {
            flex-direction: column;
        }
    }

    &.block-teaser--large.image-position--right {
        flex-direction: row-reverse;
        grid-column: 1 / span 12;

        .block_teaser_content__wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-right: 52px;

            .block_teaser__title {
                margin-top: 15px;
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h3);
                line-height: var(--l-height--h3);
            }
        }

        @include tablet-portrait {
            flex-direction: column;
        }
    }

    &.block-teaser--medium {
        flex-direction: column;
        grid-column: auto / span 8;

        .block_teaser_content__wrapper {
            display: flex;
            flex-direction: column;

            .block_teaser__title {
                margin-top: 15px;
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h4);
                line-height: var(--l-height--h4);
            }
        }

        @include tablet-portrait {
            grid-column: auto / span 12;
        }
    }

    &.block-teaser--small {
        flex-direction: column;
        grid-column: auto / span 4;

        .block_teaser_content__wrapper {
            display: flex;
            flex-direction: column;

            .block_teaser__title {
                margin-top: 10px;
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h4);
                line-height: var(--l-height--h4);
            }
        }
        .block_teaser__link {
            padding-top: 20px;
        }

        @include tablet {
            grid-column: auto / span 6;
        }

        @include mobile {
            grid-column: auto / span 12;
        }
    }

    &.block-teaser--small-wide {
        flex-direction: column;
        grid-column: auto / span 8;

        .block_teaser_content__wrapper {
            display: flex;
            flex-direction: column;

            .block_teaser__title {
                margin-top: 15px;
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h4);
                line-height: var(--l-height--h4);
            }
        }
        .block_teaser__link {
            padding-top: 20px;
        }

        @include tablet {
            grid-column: auto / span 6;
        }

        @include mobile {
            grid-column: auto / span 12;
        }
    }

    &.block-teaser--mini {
        flex-direction: column;
        grid-column: auto / span 4;

        .block_teaser_content__wrapper {
            display: flex;
            height: 140px;
            flex-direction: column;

            .block_teaser__date {
                margin-top: 10.87px;
            }

            .block_teaser__title {
                margin-top: 10px;
                margin-bottom: 20px;
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h4);
                line-height: var(--l-height--h4);
            }

            .block_teaser__teaser {
                display: none;
            }
        }

        @include tablet {
            grid-column: auto / span 6;
        }
    }

    @include tablet {
        grid-column: 3 / span 14;
    }

    @include mobile {
        grid-column: 1 / span 3;
    }
}

.block_teaser__title {
    color: v-bind(colorText);
}

.block_teaser__imgWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;

    .block-teaser--xl & {
        width: unset;
        flex-basis: 400px;
        flex-shrink: 0;
        align-self: flex-start;
    }

    .block-teaser--large & {
        width: unset;
        flex-basis: 518px;
        flex-shrink: 0;
        align-self: flex-start;

        @include tablet {
            flex-basis: 400px;
        }

        @include tablet-portrait {
            width: 100%;
            flex-basis: unset;
        }
    }
}

.block_teaser__tag {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 125px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.block_teaser_video__tag {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 92px;
    height: 50px;
    align-items: center;
    justify-content: space-around;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.block_teaser__img {
    display: block;
    background-position: center center;
    object-fit: cover;
}

.block_teaser__date {
    margin-top: 10px;
    color: v-bind(colorDate);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block_teaser__teaser {
    margin-top: 30px;
}

.block_teaser__type {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block_teaser__lead {
    p {
        margin-bottom: 20px;
    }
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-teaser-wrapper-enter-active {
    transition: opacity 0.3s ease-in;
}

.block-teaser-wrapper-enter {
    opacity: 0;
}

.block-teaser-wrapper-enter-to {
    opacity: 1;
}

/* stylelint-disable-next-line no-duplicate-selectors */
.block_teaser__img {
    width: v-bind(teaserSizeWidth);
    height: v-bind(teaserSizeHeight);

    .block-teaser-news__cards & {
        height: 210px;

        @include tablet {
            height: 152px;
        }
    }

    .block-teaser-news__swiper & {
        @include fluid('height', 152px, 257px);
    }

    .layout-news__swiper & {
        @include fluid('height', 152px, 257px);
    }

    @include tablet {
        width: 100%;
    }
}

.block_teaser_video__tag-icon {
    width: 30px;
    height: 30px;
}

.block-teaser__fancy-item {
    flex-direction: column;
    grid-column: auto / span 4;

    .block_teaser_content__wrapper {
        display: flex;
        flex-direction: column;

        .block_teaser__title {
            margin-top: 15px;
            font-family: var(--f-family--bold);
            font-size: var(--f-size--h4);
            line-height: var(--l-height--h4);
        }
    }

    .block_teaser__link {
        padding-top: 20px;
    }

    .block_teaser__img {
        height: 320px;
    }

    @include tablet {
        grid-column: auto / span 6;
    }

    @include mobile {
        grid-column: auto / span 12;
    }

    &:nth-child(7n-5),  &:nth-child(7n-1) {
        flex-direction: column;
        grid-column: auto / span 8;

        .block_teaser_content__wrapper {
            display: flex;
            flex-direction: column;

            .block_teaser__title {
                margin-top: 15px;
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h4);
                line-height: var(--l-height--h4);
            }
        }

        .block_teaser__link {
            padding-top: 20px;
        }

        @include tablet {
            grid-column: auto / span 6;
        }

        @include mobile {
            grid-column: auto / span 12;
        }
    }
}
</style>
